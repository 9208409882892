// 测评结果
import request from '@/utils/request'
// 获取计划结果列表
export function getPlanResultList(paging,gaugeId,params) {
  return request({
    url: `/plan/${gaugeId}/${paging.p}/${paging.ps}`,
    method: 'get',
    params
  });
}
 //移入回收站
 export function deletePlanResult(data) {
  return request({
    url: '/plan/result',
    method: 'delete',
    data
  });
}
// 导出excel
export function exportPlanResult(params) {
  return request({
    url: '/plan/excel/export',
    method: 'get',
    // Headers: {
    //   Access-Control-Allow-Origin: *
    // },
    params
  });
}
 // 导出pdf/word
 export function exportZIP(data) {
  return request({
    url: '/plan/batch/exportZip ',
    method: 'post',
    // Headers: {
    //   Access-Control-Allow-Origin: *
    // },
    data
  });
}
 //获取报告
 export function getReport(params) {
  return request({
    url: '/result/report',
    method: 'get',
    params
  });
}