<template>
  <!-- 查看报告 -->
  <div>
    <div class="viewReport" id="viewReport" ref="viewReport">
      <div class="region" id="pdfContent">
        <headerTopVue v-if="showBtn" class="header"></headerTopVue>
        <div class="rg">
          <div ref="pdfPrint" id="pdfPrint">
            <div class="header-test">
              <headerTopVue></headerTopVue>
            </div>
            <div class="footer-test">
              <BottomVue></BottomVue>
            </div>
            <!-- 查看报告 -->
            <div class="topInfo">
              <div class="topReport item">
                <div class="left">
                  <span>{{ evaluationInfo.nickname }}的报告</span>
                </div>
                <div class="right">
                  <span>报告生成时间：{{ evaluationInfo.reportDate }}</span>
                </div>
              </div>
              <!-- 个人信息 -->
              <div class="userInfo item">
                <p class="title">个人信息</p>
                <p>
                  姓名：<span>{{ evaluationInfo.nickname }}</span>
                </p>
                <p>
                  性别：<span>{{ evaluationInfo.sex }}</span>
                  <!-- <span v-if="evaluationInfo.sex === 1">男</span>
                                <span v-else-if="evaluationInfo.sex === 2">女</span>
                                <span v-else-if="evaluationInfo.sex === 3">未设置</span> -->
                </p>
                <p>
                  出生日期：<span v-if="evaluationInfo.birthday">{{
                    evaluationInfo.birthday.slice(0, 10)
                  }}</span>
                </p>
                <p>
                  年龄：<span>{{ evaluationInfo.age }}</span>
                </p>
                <p>
                  电话：<span>{{ evaluationInfo.telephone }}</span>
                </p>
              </div>
              <div class="EvaluationInfo item">
                <P class="title"> 测评信息 </P>
                <p>
                  量表名称：<span>{{ evaluationInfo.gaugeName }}</span>
                </p>
                <p>
                  测评时间：<span>{{ evaluationInfo.date }}</span>
                </p>
                <p>
                  量表介绍：<span>{{ evaluationInfo.memo }}</span>
                </p>
                <p>
                  测评地点：<span>{{ evaluationInfo.deptName }}</span>
                </p>
              </div>
            </div>
            <div :class="tableData.length > 0 ? '' : 'noneIcon'">
              <div class="topInfo">
                <div class="showIcon item">
                  <p class="title">因子图表</p>
                  <div
                    class="iconContent"
                    id="iconContent"
                    v-if="iconType"
                  ></div>
                  <span v-else class="iconContent" id="iconContent"
                    >未设置图表</span
                  >
                </div>
              </div>
            </div>

            <div class="topInfo" v-if="tableData.length > 0">
              <div class="excel item">
                <p class="title">因子得分</p>
                <div class="excelContent">
                  <el-table
                    :data="tableData"
                    style="width: 100%"
                    border
                    :header-cell-style="{ background: ' #F9F9F9' }"
                    size="small"
                  >
                    <el-table-column label="名称">
                      <template slot-scope="scope">
                        <span>{{ scope.row.label }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="得分">
                      <template slot-scope="scope">
                        <span>{{ scope.row.value.score }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="程度">
                      <template slot-scope="scope">
                        <span
                          v-if="
                            scope.row.value.normList.length > 0 &&
                            scope.row.value.normList[0].degree
                          "
                          >{{ scope.row.value.normList[0].degree }}</span
                        >
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="参考值">
                      <template slot-scope="scope">
                        <span
                          v-if="
                            scope.row.value.normList.length > 0 &&
                            scope.row.value.normList[0].reference
                          "
                          >{{ scope.row.value.normList[0].reference }}</span
                        >
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <div
              class="topInfo"
              :class="tableData2.length > 0 ? '' : 'noneIcon'"
            >
              <div class="showIcon item">
                <p class="title">单题计数图表</p>
                <div class="iconContent" id="iconContent2" v-if="chart"></div>
                <span v-else class="iconContent" id="iconContent2">无图表</span>
              </div>
            </div>
            <div class="topInfo" v-if="tableData2.length > 0">
              <div class="excel item">
                <p class="title">单题计数</p>
                <div class="excelContent">
                  <el-table
                    :data="tableData2"
                    style="width: 100%"
                    border
                    :header-cell-style="{ background: ' #F9F9F9' }"
                    size="small"
                  >
                    <el-table-column label="名称">
                      <template slot-scope="scope">
                        <span>{{ scope.row.name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="数量">
                      <template slot-scope="scope">
                        <span>{{ scope.row.count }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <div class="item">
              <div v-if="tableData.length > 7">
                <div class="result">
                  <p class="title">测评结果和辅导建议</p>
                  <div
                    class="proposal"
                    v-for="item in tableData.slice(0, 5)"
                    :key="item.id"
                  >
                    <p>
                      {{ item.label }}
                      <span>({{ item.value.score }})</span>
                    </p>
                    <!-- <p><span v-if="item.value.normList.length > 0">{{ item.value.normList[0].memo }}</span></p> -->
                    <p>
                      <span
                        v-if="
                          item.value.normList.length > 0 &&
                          item.value.normList[0].memoIsShow === true
                        "
                        >{{ item.value.normList[0].memo }}</span
                      >
                      <!-- <span  v-if="!item.value.normList[0].memo">无</span> -->
                    </p>
                    <p>
                      <span
                        v-if="
                          item.value.normList.length > 0 &&
                          item.value.normList[0].proposalIsShow === true
                        "
                        >{{ item.value.normList[0].proposal }}</span
                      >
                      <!-- <span v-if="!item.value.normList[0].proposal">无</span> -->
                    </p>
                  </div>
                </div>
                <div class="result">
                  <div
                    class="proposal"
                    v-for="item in tableData.slice(5, 20)"
                    :key="item.id"
                  >
                    <p>
                      {{ item.label }}
                      <span>({{ item.value.score }})</span>
                    </p>
                    <!-- <p><span v-if="item.value.normList.length > 0">{{ item.value.normList[0].memo }}</span></p> -->
                    <p>
                      <span
                        v-if="
                          item.value.normList.length > 0 &&
                          item.value.normList[0].memoIsShow === true
                        "
                        >{{ item.value.normList[0].memo }}</span
                      >
                      <!-- <span  v-if="!item.value.normList[0].memo">无</span> -->
                    </p>
                    <p>
                      <span
                        v-if="
                          item.value.normList.length > 0 &&
                          item.value.normList[0].proposalIsShow === true
                        "
                        >{{ item.value.normList[0].proposal }}</span
                      >
                    </p>
                  </div>
                  <p>{{ $store.state.comment }}</p>
                  <!-- <p>结果声明：本测试结果只反映受测者当时的状态，内容仅供参考，如有疑问，请拨打心博仕心理咨询热线或咨询专业心理咨询师。</p> -->
                </div>
                <div class="result">
                  <div
                    class="proposal"
                    v-for="item in tableData2"
                    :key="item.id"
                  >
                    <p>
                      {{ item.name }}
                      <span>({{ item.count }})</span>
                    </p>
                    <p>
                      <span
                        v-if="
                          item.countDetailList.length > 0 &&
                          item.countDetailList[0].memoIsShow === true
                        "
                        >{{ item.countDetailList[0].memo }}</span
                      >
                    </p>
                    <p>
                      <span
                        v-if="
                          item.countDetailList.length > 0 &&
                          item.countDetailList[0].proposalIsShow === true
                        "
                        >{{ item.countDetailList[0].proposal }}</span
                      >
                    </p>
                  </div>
                  <p>{{ $store.state.comment }}</p>
                  <!-- <p>结果声明：本测试结果只反映受测者当时的状态，内容仅供参考，如有疑问，请拨打源爱心理咨询热线或咨询专业心理咨询师。</p> -->
                </div>
              </div>
              <div v-else>
                <p class="title">测评结果和辅导建议</p>
                <div
                  class="proposal"
                  v-for="item in tableData.slice(0, 7)"
                  :key="item.id"
                >
                  <p>
                    {{ item.label }}
                    <span>({{ item.value.score }})</span>
                  </p>
                  <!-- <p><span v-if="item.value.normList.length > 0">{{ item.value.normList[0].memo }}</span></p> -->
                  <p>
                    <span
                      v-if="
                        item.value.normList.length > 0 &&
                        item.value.normList[0].memoIsShow === true
                      "
                      >{{ item.value.normList[0].memo }}</span
                    >
                    <!-- <span  v-if="!item.value.normList[0].memo">无</span> -->
                  </p>
                  <p>
                    <span
                      v-if="
                        item.value.normList.length > 0 &&
                        item.value.normList[0].proposalIsShow === true
                      "
                      >{{ item.value.normList[0].proposal }}</span
                    >
                    <!-- <span v-if="!item.value.normList[0].proposal">无</span> -->
                  </p>
                </div>
                <div class="proposal" v-for="item in tableData2" :key="item.id">
                  <p>
                    {{ item.name }}
                    <span>({{ item.count }})</span>
                  </p>
                  <p>
                    <span
                      v-if="
                        item.countDetailList.length > 0 &&
                        item.countDetailList[0].memoIsShow === true
                      "
                      >{{ item.countDetailList[0].memo }}</span
                    >
                  </p>
                  <p>
                    <span
                      v-if="
                        item.countDetailList.length > 0 &&
                        item.countDetailList[0].proposalIsShow === true
                      "
                      >{{ item.countDetailList[0].proposal }}</span
                    >
                  </p>
                </div>
                <p>{{ $store.state.comment }}</p>
                <!-- <p>结果声明：本测试结果只反映受测者当时的状态，内容仅供参考，如有疑问，请拨打源爱心理咨询热线或咨询专业心理咨询师。</p> -->
              </div>
            </div>
          </div>
          <BottomVue class="header"></BottomVue>
          <div class="btn" v-if="showBtn">
            <span>
              <el-dropdown style="width: 100%" @command="handleCommand">
                <span
                  class="el-dropdown-link"
                  style="width: 100%; color: white"
                >
                  导出<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">图片</el-dropdown-item>
                  <el-dropdown-item command="2">Word</el-dropdown-item>
                  <el-dropdown-item command="3">PDF</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <span class="export" @click="back">返回</span>
            <!-- <span class="export" v-print="{ id: 'pdfPrint' }">打印</span> -->
            <!-- <span class="export" @click="printOut">导出</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import JSZipUtils from "jszip-utils";
import docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import PizZip from "pizzip";
// import html2canvas from 'html2canvas';

import { getReport } from "@/api/evaluationManagement/result";
import BottomVue from "@/components/Bottom.vue";
import headerTopVue from "@/components/headerTop.vue";
// 导出为pdf
import htmlToPdf from "@/utils/htmlToPdf";
export default {
  data() {
    return {
      evaluationInfo: {
        age: null,
        birthday: null,
        date: null,
        sex: null,
        telephone: null,
        birthday: null,
        nickname: null,
        memo: null,
        dimensionNormVOMap: [],
        projectCount: [],
      }, //页面上的数据
      tableData: [],
      list: [],
      tableData2: [],
      list2: [],
      iconType: 1,
      xList: [], // x轴的数组
      yList: [], //y轴的数组
      radarList: [], // 雷达图数据
      xList2: [], // x轴的数组
      yList2: [], //y轴的数组
      radarList2: [], // 雷达图数据
      showBtn: true,
      chart: null,
      arr: [],
      arr2: [], // 导出报告时需要的数据
    };
  },
  methods: {
    // 获取数据
    async getList(val) {
      const res = await getReport({ id: val });
      console.log(res);
      if (res.code === 200) {
        this.type = res.data.type;
        this.chart = res.data.chart;
        this.evaluationInfo = res.data;
        if (this.evaluationInfo.sex === 1) {
          this.evaluationInfo.sex = "男";
        } else if (this.evaluationInfo.sex === 2) {
          this.evaluationInfo.sex = "女";
        } else {
          this.evaluationInfo.sex = "未设置";
        }
        let info = res.data.dimensionNormVOMap;
        // this.tableData2 = res.data.countDetailReportVOList;
        if (res.data.countDetailReportVOList) {
          this.tableData2 = res.data.countDetailReportVOList;
        }
        // console.log(info2);
        info.forEach((item) => {
          // console.log(Object.keys(item));
          this.tableData.push({
            label: Object.keys(item)[0],
            value: item[Object.keys(item)[0]],
          });
        });
        // info2.forEach(item => {
        //     // console.log(Object.keys(item));
        //     this.tableData2.push({
        //         label: Object.keys(item)[0],
        //         value: item[Object.keys(item)[0]]
        //     })
        // })
        // this.tableData = res.data.dimensionNormVOMap;
        this.tableData.forEach((item) => {
          this.arr.push({
            value: item.value.score,
            ...item.value.normList[0],
            label: item.label,
            isShow: item.value.isShow,
          });
        });
        this.arr.forEach((item) => {
          if (!item.memo || item.memoIsShow === false) {
            item.memo = "";
          }
          if (!item.proposal || item.proposalIsShow === false) {
            item.proposal = "";
          }
          if (item.minRange && item.maxRange) {
            if (item.firstSymbol === 1) {
              item.firstSymbol = "<";
            } else if (item.firstSymbol === 2) {
              item.firstSymbol = "≤";
            }
            if (item.secondSymbol === 1) {
              item.secondSymbol = "<";
            } else if (item.secondSymbol === 2) {
              item.secondSymbol = "≤";
            }
          }
          item.formula = "";
          // let formula = null;
          if (item.minRange && item.maxRange) {
            item.formula = `${item.minRange}${item.firstSymbol}X${item.secondSymbol}${item.maxRange}`;
          }
        });
        // console.log(this.tableData)
        this.tableData2.forEach((item) => {
          this.arr2.push({
            ...item.countDetailList[0],
            label: item.name,
            number: item.count,
          });
        });

        this.arr2.forEach((item) => {
          if (!item.memo || item.memoIsShow === 0) {
            item.memo = "";
          }
          if (!item.proposal || item.proposalIsShow === 0) {
            item.proposal = "";
          }
        });
      }
      let xData = [];
      let yData = [];
      this.tableData.forEach((item) => {
        // console.log(item)
        if (item.value.isShow === "true" || item.value.isShow) {
          yData.push(item.label);
          this.radarList.push({ name: item.label });
          xData.push(item.value.score);
        }
      });
      this.xList = [...xData];
      this.yList = [...yData];
      this.yList.forEach((item, index) => {
        this.list.push({ name: item, value: this.xList[index] });
      });
      this.echartsInit();
      let xData2 = [];
      let yData2 = [];
      console.log(this.tableData2);
      this.tableData2.forEach((item) => {
        console.log(item);
        // if (item.value.isShow === 'true' || item.value.isShow) {
        this.radarList2.push({ name: item.name });
        yData2.push(item.name);
        xData2.push(item.count);
        // }
      });
      this.xList2 = [...xData2];
      this.yList2 = [...yData2];
      this.yList2.forEach((item, index) => {
        this.list2.push({ name: item, value: this.xList[index] });
      });
      this.$nextTick(() => {
        this.echartsInit2();
      });
    },
    // 点击返回
    back() {
      if(this.$route.path === '/home/viewReport'){
        this.$router.push("/home/result");
      } else if(this.$route.path === '/home/warningReport') {
        this.$router.push("/home/personalWarning");
      } else if(this.$route.path === '/home/fileDetails2') {
        this.$router.push("/home/healthRecords/record");
      } else {
        this.$router.push("/home/result");
      }
      
    },
    echartsInit() {
      console.log(this.xList);
      console.log(this.yList);
      let option = {};
      if (this.type === 1) {
        option = {
          // title: {
          //     text: "图表显示"
          // },
          tooltip: {},
          xAxis: {},
          yAxis: { data: this.yList },
          series: [
            {
              name: "分值",
              type: "bar",
              data: this.xList,
              color: "#5B87A3",
            },
          ],
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
        };
      } else if (this.type === 2) {
        // icon = 'line'
        option = {
          // title: {
          //     text: "图表显示"
          // },
          tooltip: {},
          xAxis: {},
          yAxis: { data: this.yList },
          series: [
            {
              name: "分值",
              type: "line",
              data: this.xList,
              color: "#5B87A3",
            },
          ],
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
        };
      } else if (this.type === 3) {
        // icon = 'pie'
        //     option = {
        //         series: [
        //             {
        //                 type: 'pie',
        //                 data: this.list
        //             }
        //         ]
        // };
        option = {
          // title: {
          //     text: "图表显示"
          // },
          tooltip: {},
          xAxis: {},
          yAxis: { data: this.yList },
          series: [
            {
              name: "分值",
              type: "line",
              data: this.xList,
              color: "#5B87A3",
              areaStyle: {},
            },
          ],
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
        };
      } else if (this.type === 4) {
        option = {
          // title: {
          //   text: '素质能力',
          //   left: 'center',
          //   bottom: 'bottom',
          //   textStyle: {
          //     fontSize: 14
          //   }
          // },
          radar: {
            // radius: 68, // 雷达图半径
            axisName: {
              color: "#555",
            },
            // shape: 'circle',
            // indicator: [
            //   { name: '学习能力', max: 100 }, // 上右下左
            //   { name: '协作能力', max: 100 },
            //   { name: '代码规范', max: 100 },
            //   { name: '执行能力', max: 100 },
            //   { name: '管理能力', max: 100 },
            //   { name: '沟通能力', max: 100 }
            // ],
            indicator: this.radarList.map(function (item) {
              item.name = item.name;
              item.max = 10;
              return item;
            }),
            splitArea: {
              areaStyle: {
                color: ["#fff"],
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            splitLine: {
              lineStyle: {
                color: ["#D5DEFD", "#D5DEFD", "#D5DEFD"],
              },
            },
          },
          series: [
            {
              name: "多边雷达图",
              type: "radar",
              symbolSize: 10,
              areaStyle: {
                normal: {
                  color: "#DCE8ED",
                },
              },
              data: [
                {
                  //   value: [120,130,120,140,141,121,131,151,124,136,133,166],
                  value: this.xList,
                  //   value: option.map(function (item) {,
                  //     return item.sum;
                  //   }),
                  itemStyle: {
                    normal: {
                      color: "#2FC0E0",
                    },
                  },
                  label: {
                    show: true,
                    formatter: this.xList,
                  },
                },
              ],
            },
          ],
        };
      }

      this.$nextTick(() => {
        let myEchart = this.$echarts.init(
          document.getElementById("iconContent")
        );
        myEchart.setOption(option);
      });
    },
    echartsInit2() {
      console.log(this.xList2);
      console.log(this.yList2);
      let option = {};
      if (this.chart === 1) {
        option = {
          // title: {
          //     text: "图表显示"
          // },
          tooltip: {},
          xAxis: {},
          yAxis: {
            data: this.yList2,
          },
          series: [
            {
              name: "分值",
              type: "bar",
              data: this.xList2,
              color: "#5B87A3",
            },
          ],
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
        };
      } else if (this.chart === 2) {
        // icon = 'line'
        option = {
          // title: {
          //     text: "图表显示"
          // },
          tooltip: {},
          xAxis: {},
          yAxis: { data: this.yList2 },
          series: [
            {
              name: "分值",
              type: "line",
              data: this.xList2,
              color: "#5B87A3",
            },
          ],
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
        };
      } else if (this.chart === 3) {
        // icon = 'pie'
        //     option = {
        //         series: [
        //             {
        //                 type: 'pie',
        //                 data: this.list
        //             }
        //         ]
        // };
        option = {
          // title: {
          //     text: "图表显示"
          // },
          tooltip: {},
          xAxis: {},
          yAxis: { data: this.yList2 },
          series: [
            {
              name: "分值",
              type: "line",
              data: this.xList2,
              color: "#5B87A3",
              areaStyle: {},
            },
          ],
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
        };
      } else if (this.chart === 4) {
        option = {
          // title: {
          //   text: '素质能力',
          //   left: 'center',
          //   bottom: 'bottom',
          //   textStyle: {
          //     fontSize: 14
          //   }
          // },
          radar: {
            // radius: 68, // 雷达图半径
            axisName: {
              color: "#555",
            },
            // shape: 'circle',
            // indicator: [
            //   { name: '学习能力', max: 100 }, // 上右下左
            //   { name: '协作能力', max: 100 },
            //   { name: '代码规范', max: 100 },
            //   { name: '执行能力', max: 100 },
            //   { name: '管理能力', max: 100 },
            //   { name: '沟通能力', max: 100 }
            // ],
            indicator: this.radarList2.map(function (item) {
              item.name = item.name;
              item.max = 10;
              return item;
            }),
            splitArea: {
              areaStyle: {
                color: ["#fff"],
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            splitLine: {
              lineStyle: {
                color: ["#D5DEFD", "#D5DEFD", "#D5DEFD"],
              },
            },
          },
          series: [
            {
              name: "多边雷达图",
              type: "radar",
              symbolSize: 10,
              areaStyle: {
                normal: {
                  color: "#DCE8ED",
                },
              },
              data: [
                {
                  //   value: [120,130,120,140,141,121,131,151,124,136,133,166],
                  value: this.xList2,
                  //   value: option.map(function (item) {,
                  //     return item.sum;
                  //   }),
                  itemStyle: {
                    normal: {
                      color: "#2FC0E0",
                    },
                  },
                  label: {
                    show: true,
                    formatter: this.xList2,
                  },
                },
              ],
            },
          ],
        };
      }
      let myEchart = this.$echarts.init(
        document.getElementById("iconContent2")
      );
      myEchart.setOption(option);
    },
    // 实现导出页面为图片
    // ---------------分割线-------------------
    dataURLToBlob(dataurl) {
      //ie 图片转格式
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    downloadResult(name) {
      let canvasID = document.getElementById("viewReport");
      let a = document.createElement("a");
      html2canvas(canvasID).then((canvas) => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = "none";
        a.style.display = "none";
        document.body.removeChild(dom);
        let blob = this.dataURLToBlob(dom.toDataURL("image/png"));
        a.setAttribute("href", URL.createObjectURL(blob));
        a.setAttribute("download", name + ".png");
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);
      });
    },
    // 点击导出为图片
    printOut() {
      let name = `${this.evaluationInfo.nickname} -报告`;
      // 个人观察只是截取可见范围以及以下的区域，所以先将滚动条置顶
      // $(window).scrollTop(0); // jQuery 的方法
      document.body.scrollTop = 0; // IE的
      document.documentElement.scrollTop = 0; // 其他
      this.downloadResult(name);
    },
    // 点击打印
    print() {
      this.showBtn = false;
      this.$nextTick(() => {
        print(this.$refs.pdfPrint, { noPrint: "#topM" });
        this.showBtn = true;
      });
    },
    // 导出word
    ExportWord() {
      this.getImg();
      this.getImg2();
      //这里要引入处理图片的插件，下载docxtemplater后，引入的就在其中了
      var ImageModule = require("docxtemplater-image-module-free");
      var _this = this;
      //Word路径，在public文件下(CLI3)
      JSZipUtils.getBinaryContent("import.docx", function (error, content) {
        if (error) {
          throw error;
        }
        let opts = {};
        opts.centered = true;
        opts.fileType = "docx";
        opts.getImage = (tag) => {
          return _this.base64DataURLToArrayBuffer(tag);
        };
        opts.getSize = () => {
          return [600, 400]; //这里可更改输出的图片宽和高
        };
        let zip = new PizZip(content);
        let doc = new docxtemplater();
        doc.attachModule(new ImageModule(opts));
        doc.loadZip(zip);
        doc.setData({
          ..._this.evaluationInfo, //基础信息
          arr: _this.arr, // 评语
          projectCount: _this.arr2,
        });
        try {
          doc.render();
        } catch (error) {
          var e = {
            message: error.message,
            name: error.name,
            stack: error.stack,
            properties: error.properties,
          };
          console.log(
            JSON.stringify({
              error: e,
            })
          );
          throw error;
        }
        var out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        saveAs(out, `${_this.evaluationInfo.nickname}的测评报告.docx`);
      });
    },
    //这里是处理图片的代码
    base64DataURLToArrayBuffer(dataURL) {
      const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
      if (!base64Regex.test(dataURL)) {
        return false;
      }
      const stringBase64 = dataURL.replace(base64Regex, "");
      let binaryString;
      if (typeof window !== "undefined") {
        binaryString = window.atob(stringBase64);
      } else {
        binaryString = new Buffer(stringBase64, "base64").toString("binary");
      }
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes.buffer;
    },
    // 处理echars图表
    getImg() {
      let option = {};
      if (this.type === 1) {
        option = {
          tooltip: {},
          xAxis: {},
          yAxis: { data: this.yList },

          series: [
            {
              name: "分值",
              type: "bar",
              data: this.xList,
              color: "#5B87A3",
            },
          ],
          dataZoom: [
            {
              type: "inside",
            },
          ],
        };
      } else if (this.type === 2) {
        // icon = 'line'
        option = {
          tooltip: {},
          xAxis: {},
          yAxis: {
            data: this.yList,
          },
          series: [
            {
              name: "分值",
              type: "line",
              data: this.xList,
              color: "#5B87A3",
            },
          ],
          dataZoom: [
            {
              type: "inside",
            },
          ],
        };
      } else if (this.type === 3) {
        option = {
          tooltip: {},
          xAxis: {},
          yAxis: {
            data: this.yList,
          },
          series: [
            {
              name: "分值",
              type: "line",
              data: this.xList,
              color: "#5B87A3",
              areaStyle: {},
            },
          ],
          dataZoom: [
            {
              type: "inside",
            },
          ],
        };
      } else if (this.type === 4) {
        option = {
          radar: {
            axisName: {
              color: "#555",
            },
            indicator: this.radarList.map(function (item) {
              item.name = item.name;
              item.max = 10;
              return item;
            }),
            splitArea: {
              areaStyle: {
                color: ["#fff"],
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            splitLine: {
              lineStyle: {
                color: ["#D5DEFD", "#D5DEFD", "#D5DEFD"],
              },
            },
          },
          series: [
            {
              name: "多边雷达图",
              type: "radar",
              symbolSize: 10,
              areaStyle: {
                normal: {
                  color: "#DCE8ED",
                },
              },
              data: [
                {
                  //   value: [120,130,120,140,141,121,131,151,124,136,133,166],
                  value: this.xList,
                  //   value: option.map(function (item) {,
                  //     return item.sum;
                  //   }),
                  itemStyle: {
                    normal: {
                      color: "#2FC0E0",
                    },
                  },
                  label: {
                    show: true,
                    formatter: this.xList,
                  },
                },
              ],
            },
          ],
        };
      }
      let myEchart = this.$echarts.init(document.getElementById("iconContent"));

      myEchart.setOption(option);
      // 获取echart图片
      this.evaluationInfo.imgUrl = myEchart.getDataURL({
        pixelRatio: 2, // 导出的图片分辨率比例，默认为 1。
        backgroundColor: "#fff", // 导出的图片背景色，默认使用 option 里的 backgroundColor
      });
    },
    // 处理echars图表
    getImg2() {
      let option = {};
      if (this.chart === 1) {
        option = {
          tooltip: {},
          xAxis: {},
          yAxis: { data: this.yList2 },

          series: [
            {
              name: "分值",
              type: "bar",
              data: this.xList2,
              color: "#5B87A3",
            },
          ],
          dataZoom: [
            {
              type: "inside",
            },
          ],
        };
      } else if (this.chart === 2) {
        // icon = 'line'
        option = {
          tooltip: {},
          xAxis: {},
          yAxis: {
            data: this.yList2,
          },
          series: [
            {
              name: "分值",
              type: "line",
              data: this.xList2,
              color: "#5B87A3",
            },
          ],
          dataZoom: [
            {
              type: "inside",
            },
          ],
        };
      } else if (this.chart === 3) {
        option = {
          tooltip: {},
          xAxis: {},
          yAxis: {
            data: this.yList2,
          },
          series: [
            {
              name: "分值",
              type: "line",
              data: this.xList2,
              color: "#5B87A3",
              areaStyle: {},
            },
          ],
          dataZoom: [
            {
              type: "inside",
            },
          ],
        };
      } else if (this.chart === 4) {
        option = {
          radar: {
            axisName: {
              color: "#555",
            },
            indicator: this.radarList2.map(function (item) {
              item.name = item.name;
              item.max = 10;
              return item;
            }),
            splitArea: {
              areaStyle: {
                color: ["#fff"],
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
            },
            splitLine: {
              lineStyle: {
                color: ["#D5DEFD", "#D5DEFD", "#D5DEFD"],
              },
            },
          },
          series: [
            {
              name: "多边雷达图",
              type: "radar",
              symbolSize: 10,
              areaStyle: {
                normal: {
                  color: "#DCE8ED",
                },
              },
              data: [
                {
                  //   value: [120,130,120,140,141,121,131,151,124,136,133,166],
                  value: this.xList2,
                  //   value: option.map(function (item) {,
                  //     return item.sum;
                  //   }),
                  itemStyle: {
                    normal: {
                      color: "#2FC0E0",
                    },
                  },
                  label: {
                    show: true,
                    formatter: this.xList2,
                  },
                },
              ],
            },
          ],
        };
      }
      let myEchart = this.$echarts.init(
        document.getElementById("iconContent2")
      );

      myEchart.setOption(option);
      // 获取echart图片
      this.evaluationInfo.imgUrl2 = myEchart.getDataURL({
        pixelRatio: 2, // 导出的图片分辨率比例，默认为 1。
        backgroundColor: "#fff", // 导出的图片背景色，默认使用 option 里的 backgroundColor
      });
    },
    // 导出为PDF
    printPdf() {
      // htmlToPdf('viewReport', `${this.evaluationInfo.nickname}-测评报告`);
      this.showBtn = false;
      this.$nextTick(() => {
        htmlToPdf.downloadPDF(
          document.querySelector("#pdfContent"),
          `${this.evaluationInfo.nickname}-测评报告`
        );
        this.showBtn = true;
      });
    },
    // 用户选择导出方式
    handleCommand(val) {
      // console.log(val)
      if (val === "1") {
        this.printOut();
      } else if (val === "2") {
        this.ExportWord();
      } else if (val === "3") {
        this.$nextTick(() => {
          this.printPdf();
        });
      }
    },
  },
  created() {
    this.getList(this.$route.query.id);
    //  this.getList(Number(sessionStorage.getItem('ReportId')));
  },
  components: { BottomVue, headerTopVue },
};
</script>

<style lang="scss" scoped>
.viewReport {
  .noneIcon {
    display: none;
  }

  .region {
    background: white;
    margin: auto;

    .item {
      margin-bottom: 30px;
    }

    .btn {
      display: flex;
      align-items: center;
      height: 40px;
      width: 100%;
      background: var(--custom-color);
      border-radius: 4px 0px 0px 4px;
      color: white;
      margin-bottom: 30px;
      cursor: pointer;

      span {
        display: inline-block;
        width: 50%;
        box-sizing: border-box;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        // line-height: 100%;
      }

      .export {
        border-left: 1px solid #ffffff;
      }
    }

    .showIcon {
      .iconContent {
        height: 400px;
        width: 100%;
        // background: #028DA9;
      }
    }

    .excel {
      .excelContent {
        ::v-deep .el-table .cell {
          font-size: 14px !important;
        }
      }
    }

    .title {
      font-weight: 600;
      font-size: 16px;
    }

    p {
      font-size: 14px;
      color: #303030;

      span {
        color: #303030;
      }
    }
  }
}

@media screen and (max-width: 2160px) and (min-width: 751px) {
  .region {
    width: 900px;

    .rg {
      .topReport {
        // width: 650px;
        padding: 0px 10px;
        height: 40px;
        background: var(--btn-color);
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          color: white;
          font-size: 14px;
          line-height: 40px;
        }

        .left,
        .right {
          // line-height: 40px;
          height: 40px;
        }
      }
    }

    .rg {
      padding: 40px 100px;

      .topReport {
        width: 650px;
        // height: 40px;
        // background: #028DA9;
      }
    }

    // background: white;
  }
}

@media screen and (max-width: 750px) {
  .region {
    width: 100%;

    .rg {
      .topReport {
        // width: 650px;
        padding: 0px 10px;
        height: 60px;
        background: var(--top-color);

        span {
          color: white;
          font-size: 14px;
        }

        .left,
        .right {
          width: 100%;
          line-height: 30px;
          // height: 30px;
        }
      }
    }

    .rg {
      padding-top: 20px;
      // padding: 20px 20px;
      width: 90%;
      margin: auto;

      // .topReport {
      //     // width: 100%;
      //     // margin: auto;
      //     // width: 90%;
      //     // height: 40px;
      //     // background: #028DA9;
      // }
    }

    // background: white;
  }
}

@media print {
  // 设置打印页眉页脚的高度
  .header-test,
  .header,
  .footer-test,
  .footer {
    height: 130px;
  }

  .footer {
    page-break-after: always;
  }

  .header {
    display: none;
  }

  // 强制进行分页
  .topInfo,
  .another {
    height: 1080px;
    // margin: 100px;
    // padding: 30mm 0;
    padding-top: 30mm;
  }

  .result {
    height: 1050px;
  }

  .header-test {
    position: fixed;
    top: 0;
  }

  .footer-test {
    position: fixed;
    bottom: 0;
  }

  // thead {
  //     display: table-header-group;
  // }

  // tfoot {
  //     display: table-footer-group;
  // }

  // -------分界线(上面就可以实现每页上都有页眉页脚了)-------------
  #printtest {
    display: none;
  }

  .header-test,
  .footer-test {
    display: block;
  }
}

// # 网页上隐藏自定义的页眉页脚，打印时才显示
@media screen {
  #printtest {
    display: block;
    z-index: 2222;
    // display:block;
    height: auto;
    overflow: hidden;
    // height: 200.5mm;
  }

  .header-test,
  .footer-test {
    display: none;
  }
}

// 打印媒体查询
@media print {
  @page {
    size: auto;
    margin: 30mm 30mm 25mm 32mm;
    padding: 20mm !important;
    //   margin: 80mm 0;
    //   position: absolute;
    //   padding-top: 60px;
    //   padding-bottom: 150px;
    //   padding: 20;
  }

  // .another {
  //     height: 600px;
  // }

  //    body, html {
  //           height: 60mm !important;
  //         //   padding-top: 30mm;
  //         //   position: absolute;
  //         }
}
</style>